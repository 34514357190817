import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';
const apiURL = 'https://react.squatz.com/backend/';
// const apiURL = 'http://localhost/backend/';
export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}
async function checkUserSession(uid: string, session: string) {
  return false;
  /*
  fetch(apiURL+ 'ajax/restApi/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: UserId,
      session: URLRoomName,
      p: 'checkIfUserExistInSession',
    }),
  })
    .then(response => response.json())
    .then(responseJson => {
      if(responseJson) {
        setStep(Steps.deviceSelectionStep);
      }else{
        setStep(Steps.roomNameStep)
      }
    })
    .catch(function(error) {
      setStep(Steps.roomNameStep)
    });
    */
}
export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams();
  const { URLName } = useParams();
  const { URLUserId } = useParams();
  const [step, setStep] = useState(Steps.deviceSelectionStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [UserId, setUserId] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (URLRoomName && URLUserId && URLName) {
      setUserId(URLUserId);
      setName(URLName);
      setRoomName('Room_' + URLRoomName);
      //if (user?.displayName) {
      fetch(apiURL + 'ajax/restApi/', {
        // mode: 'no-cors',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: URLUserId,
          session: URLRoomName,
          p: 'checkIfUserExistInSession',
        }),
      })
        .then(response => {
          //alert(JSON.stringify(response));
          return response.json();
        })
        .then(responseJson => {
          //alert(responseJson);
          if (responseJson) {
            setIsValid(true);
            setStep(Steps.deviceSelectionStep);
          } else {
            setStep(Steps.roomNameStep);
          }
        })
        .catch(function(error) {
          alert(error);
          setStep(Steps.roomNameStep);
        });
      //setStep(Steps.deviceSelectionStep);
    } else {
      setStep(Steps.roomNameStep);
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (URLName && URLUserId && URLRoomName) {
      setUserId(URLUserId);
      setRoomName('Room_' + URLRoomName);
      setName(URLName);
      if (user?.displayName && checkUserSession(UserId, URLRoomName)) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <IntroContainer subContent={step === Steps.deviceSelectionStep && SubContent}>
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          UserId={UserId}
          isValid={isValid}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
